<template>
        <el-dialog title="Top banner" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item prop="Url" label='Url' >
                    <el-input  class='full-width' v-model='selectedObject.Url' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="Nume" label='Nume' >
                    <el-input  class='full-width' v-model='selectedObject.Nume' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="Pozitie" label='Pozitie' >
                    <el-input-number class='full-width' v-model='selectedObject.Pozitie' :precision='0' :max='999999'></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :md='24'>
                <el-form-item prop="uploadPreview_ImagineMobil" label='Imagine mobil' >
                <el-upload
                        class='my-uploader'
                        :action="baseUrl + 'Top_banner/upload_ImagineMobil'"
                        :show-file-list='false'
                        :on-success='handleUploadSuccess_ImagineMobil'>
                            <i class='el-icon-plus my-uploader-icon'></i>
                        </el-upload>       
                        <img class="imagePreview" v-if='selectedObject.uploadPreview_ImagineMobil' :src='selectedObject.uploadPreview_ImagineMobil' > 
                </el-form-item>
            </el-col>
            <el-col :md='24'>
                <el-form-item prop="uploadPreview_ImagineDesktop" label='Imagine desktop' >
                <el-upload
                        class='my-uploader'
                        :action="baseUrl + 'Top_banner/upload_ImagineDesktop'"
                        :show-file-list='false'
                        :on-success='handleUploadSuccess_ImagineDesktop'>
                            <i class='el-icon-plus my-uploader-icon'></i>
                        </el-upload>       
                        <img class="imagePreview" v-if='selectedObject.uploadPreview_ImagineDesktop' :src='selectedObject.uploadPreview_ImagineDesktop' > 
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Top_banner_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    Url: '' , Nume: '' , Pozitie: '' , ImagineMobil: '' , uploadPreview_ImagineMobil : '' , ImagineDesktop: '' , uploadPreview_ImagineDesktop : '' ,                 },
                Info:{
                                    },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    Url: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Pozitie: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    ImagineMobil: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    ImagineDesktop: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                    this.selectedObject = {};
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("top_banner/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("top_banner/get_info_for_dialog" );
                            },
             handleUploadSuccess_ImagineMobil(response){
                    this.selectedObject.ImagineMobil = response.FileName;
                    this.selectedObject.uploadPreview_ImagineMobil  = response.Url;
                },
         handleUploadSuccess_ImagineDesktop(response){
                    this.selectedObject.ImagineDesktop = response.FileName;
                    this.selectedObject.uploadPreview_ImagineDesktop  = response.Url;
                },
                    save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("top_banner/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

    .imagePreview {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

</style>